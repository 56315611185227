import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular-ivy";
import { enableProdMode } from "@angular/core";

Sentry.init({
    dsn: "https://a3d71082bb23d69f0ed5dbb308f971e8@o4505265587879936.ingest.sentry.io/4506213830492160"
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
