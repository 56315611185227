import { PartOfSpeech } from "@interface/nlp";
import { YoutubeVideoFragment } from "@interface/youtube";
import { FromAny, Validate, isBoolean, isNumber, isString, notEmpty } from "@utils/class-from-any";


export {
    DictionaryQueryRequest,
    DictionaryQueryRequestFromParams,
    DictionaryQueryResponse,
    DictionaryQueryResults,
    YoutubeVideoFragmentFromIndex,
    queryType as DictionaryQueryType,
    queryTypes as DictionaryQueryTypes,
    validStatus as DictionaryValidStatus,
    validStatuses as DictionaryValidStatuses,
    resultType as DictionaryResultType,
    resultTypes as DictionaryResultTypes,
    sentimentTypes,
    sentimentType,
    reasonOfEmptyResult as DictionaryQueryReasonOfEmptyResult,
    reasonsOfEmptyResult as DictionaryQueryReasonsOfEmptyResult
};

// Sentiment

const sentimentTypes = {
    veryNegative: "veryNegative",
    negative: "negative",
    positive: "positive",
    veryPositive: "veryPositive"
} as const;

type sentimentType = keyof typeof sentimentTypes;

// Reasons of empty result

const reasonsOfEmptyResult = {
    tooManyWords: "tooManyWords",
    noWords: "noWords",
    nothingFound: "nothingFound",
    incorrectSpelling: "incorrectSpelling"
} as const;

type reasonOfEmptyResult = keyof typeof reasonsOfEmptyResult;

// Request

interface DictionaryQueryRequest {
    madeForKids: boolean;
    videoCategoryId: number;
    text: string;
    num: number; // Номер запроса. Увеличивается, если надо повторно сделать запрос,
    // например, для дополучения текстов предложений. Нужен для того, чтобы запрос
    // не кэшировался на клиенте.
}

class DictionaryQueryRequestFromParams extends FromAny implements DictionaryQueryRequest {
    @Validate(isBoolean) madeForKids: boolean;
    @Validate(isNumber) videoCategoryId: number;
    @Validate(isString, notEmpty) text: string;
    @Validate(isNumber) num: number;
}

// Response

const queryTypes = {
    unknown: 0,
    word: 1,
    phrase: 2
} as const;

type queryType = keyof typeof queryTypes;

const validStatuses = {
    unknown: 0, // поиск не дал результата или не прошла проверка орфографии или не прошла проверку валидация запроса
    valid: 1, // прошла проверка орфографии и поиск дал результаты
    invalid: 2 // не прошла проверка орфографии и поиск не дал результат
} as const;

type validStatus = keyof typeof validStatuses;

const resultTypes = {
    empty: 0, // Нет ничего в индексе (но может быть найдено в Youglish)
    partial: 1, // Найдено мало вариантов
    full: 2, // Найдено много вариантов 
} as const;

type resultType = keyof typeof resultTypes;

interface YoutubeVideoFragmentFromIndex extends YoutubeVideoFragment {
    shortYear: number;
    channelNum: number;
    wordsPerMinuteInterval: number; // Интервал в который попало видео по метрике "Среднее число слов в минуту"
    sentenceLengthInterval: number; // Интервал в который попало видео по метрике "Средняя длина предложений"
    sentimentInterval: number; // Интервал в который попало видео по метрике "Среднее настроение"
    sentenceSentimentType: sentimentType; // Настроение конкретного предложения
    sentenceLength: number; // Длина конкретного предложения
    partOfSpeech?: PartOfSpeech; // Часть речи только, когда ищется одно слово
    lemma?: string; // Лемма слова, когда ищется одно слово
    sentenceText?: string; // Текст предложения, в котором встретилось слово или фраза (может отсутствовать)
}

interface DictionaryQueryResults {
    reasonOfEmptyResult?: reasonOfEmptyResult;
    needToGetSentenceText?: boolean; // Свойство sentenceText не было получено и его еще надо будет получить
    youtubeFragmentsFromIndex?: YoutubeVideoFragmentFromIndex[];
    youtubeFragmentsFromYouglish?: YoutubeVideoFragment[];
}

interface DictionaryQueryResponse {
    query: string;
    madeForKids: boolean;
    videoCategoryId: number;
    queryType: queryType;
    validStatus: validStatus;
    resultType: resultType;
    results: DictionaryQueryResults;
    dateModified: Date;
    fromCache: boolean;
}
