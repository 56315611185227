import { YoutubeCategory, youtubeCategories } from "@interface/youtube";
// Этот класс не должен зависеть от Angular, так как используется на сервере

export { YoutubeListenRoutingClass, YoutubeListenParams };

class YoutubeCategoryWithPath extends YoutubeCategory {
    path: string;
}

interface YoutubeListenParams {
    text: string;
    categoryId: number;
}

class YoutubeListenRoutingClass {
    static pathTemplate = "youtube/actual/mainstream/:category/:text";

    static getPath(youtubeListenParams: YoutubeListenParams): string[] {
        return [
            "/youtube",
            "actual",
            "mainstream",
            YoutubeListenRoutingClass.categoryPathFromId(youtubeListenParams.categoryId),
            YoutubeListenRoutingClass.textToParam(youtubeListenParams.text)
        ];
    }

    static getPathAsURL(youtubeListenParams: YoutubeListenParams): string {
        const path = YoutubeListenRoutingClass.getPath(youtubeListenParams);
        return path.join("/");
    }

    static getParams(routerParams: { [key: string]: unknown }): YoutubeListenParams | undefined {
        if (!routerParams["text"] || typeof routerParams["text"] !== "string") {
            return undefined;
        }
        if (!routerParams["category"] || typeof routerParams["category"] !== "string") {
            return undefined;
        }
        const text = YoutubeListenRoutingClass.paramToText(routerParams["text"]);
        const category = YoutubeListenRoutingClass.categoryFromPath(routerParams["category"]);
        if (!category) {
            return undefined;
        }
        const youtubeListenParams: YoutubeListenParams = {
            text: text,
            categoryId: category.id
        };
        return youtubeListenParams;
    }

    //> Private

    private static textToParam(text: string): string {
        return text.trim().toLocaleLowerCase().replaceAll(" ", "_");
    }

    private static paramToText(param: string): string {
        return param.replaceAll("_", " ");
    }

    private static categoriesWithPaths(): YoutubeCategoryWithPath[] {
        return youtubeCategories.items.map((val) => {
            const path = val.title
                .toLocaleLowerCase()
                .replaceAll("&", "and")
                .replaceAll(" ", "_")
                .replaceAll("/", "_or_");
            return {
                ...val,
                path: path
            };
        });
    }

    private static categoryPathFromId(categoryId: number): string {
        const categories = YoutubeListenRoutingClass.categoriesWithPaths();
        const category = categories.find((val) => val.id === categoryId);
        if (category === undefined) {
            throw new Error("Category is undefined");
        }
        return category.path;
    }

    private static categoryFromPath(path: string): YoutubeCategoryWithPath | undefined {
        return YoutubeListenRoutingClass.categoriesWithPaths().find((val) => val.path === path);
    }
}
